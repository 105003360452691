import React, { useContext } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { CommonProps, RestrictedProps } from '../../../../types/common';
import { classes } from './Restricted.st.css';

interface RestrictedViewConfiguration {
  title: string;
  button: string;
  link: string;
  onButtonClick(): void;
  onLinkClick(): void;
}

const RestrictedTextsContext = React.createContext<RestrictedViewConfiguration>({
  title: '',
  button: '',
  link: '',
  onButtonClick() {},
  onLinkClick() {},
});

const Restricted: React.FC<RestrictedProps & CommonProps> = ({
  missingPlan,
  navigateToHomePage,
  switchAccounts,
  navigateToPlanList,
}) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();

  const texts = React.useMemo<RestrictedViewConfiguration>(() => {
    return missingPlan
      ? {
          title: t('restricted.title'),
          button: t('restricted.check-plans'),
          link: t('restricted.back'),
          onButtonClick: navigateToPlanList,
          onLinkClick: navigateToHomePage,
        }
      : {
          title: t('restricted.other-title'),
          button: t('restricted.back-to-site'),
          link: t('restricted.switch-account'),
          onButtonClick: navigateToHomePage,
          onLinkClick: switchAccounts,
        };
  }, [missingPlan, navigateToPlanList, navigateToHomePage, switchAccounts, t]);

  return (
    <RestrictedTextsContext.Provider value={texts}>
      <div dir={isRTL ? 'rtl' : 'ltr'} data-hook="restricted-app" className={classes.wrapper}>
        {isMobile ? <Mobile /> : <Desktop />}
      </div>
    </RestrictedTextsContext.Provider>
  );
};

const Mobile: React.FC = () => (
  <div className={classes.mobileContainer}>
    <Title maxWidth={260} fontSize="28px" />
    <div className={classes.mobileButton}>
      <Button />
    </div>
    <div className={classes.mobileLink}>
      <Link fontSize="15px" />
    </div>
  </div>
);

const Desktop: React.FC = () => (
  <div className={classes.desktopContainer}>
    <Title maxWidth={600} fontSize="46px" />
    <div className={classes.desktopButton}>
      <Button />
    </div>
    <div className={classes.desktopLink}>
      <Link fontSize="18px" />
    </div>
  </div>
);

interface TitleProps {
  maxWidth: number;
  fontSize: string;
}

const Title: React.FC<TitleProps> = ({ maxWidth, fontSize }) => {
  const { title } = useContext(RestrictedTextsContext);
  return (
    <div className={classes.title} style={{ maxWidth, width: maxWidth, fontSize }} data-hook="restricted-title">
      {title}
    </div>
  );
};

const Button: React.FC = () => {
  const { button, onButtonClick } = useContext(RestrictedTextsContext);
  return (
    <div onClick={() => onButtonClick()} className={classes.button} data-hook="restricted-button">
      {button}
    </div>
  );
};

interface LinkProps {
  fontSize: string;
}

const Link: React.FC<LinkProps> = ({ fontSize }) => {
  const { link, onLinkClick } = useContext(RestrictedTextsContext);
  return (
    <span onClick={() => onLinkClick()} className={classes.link} style={{ fontSize }} data-hook="restricted-link">
      {link}
    </span>
  );
};

export default Restricted;
